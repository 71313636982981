import React from "react";
import { Button, Intent, Position, Spinner, Toaster } from "@blueprintjs/core";
import { ssoInstance } from "gg-react-utilities";
import config from "../../../core/Config";

export default class SingleRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadingInvoice: false,
    };
  }
  renderOperatorImage(operator) {
    switch (operator) {
      case "idea":
        return (
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAfGcdMA7iPPMYEjqL6-P-_iIfc9pfqyPs_qicYfn-mdBmkS2R"
            alt="Idea"
          />
        );
      default:
        return "";
    }
  }
  downloadInvoice = (orderId) => {
    this.setState({ downloadingInvoice: true });
    ssoInstance
      .post(
        config.GET_PDF,
        { order_id: orderId },
        { responseType: "arraybuffer" }
      )
      .then((res) => {
        let a = document.createElement("a");
        let file = new Blob([res.data], { type: "application/pdf" });
        let fileURL = URL.createObjectURL(file);
        a.href = fileURL;
        a.download = "gg_invoice-" + orderId + ".pdf";
        a.click();
        this.setState({ downloadingInvoice: false });
        //window.open(fileURL);
      })
      .catch((error) => {
        let message = error.response.data.message
          ? error.response.data.message
          : "Something went wrong, try again.";
        Toaster.create({
          className: "recipe-toaster",
          position: Position.BOTTOM,
        }).show({ message: message, intent: Intent.DANGER });
        this.setState({ downloadingInvoice: false });
      });
  };
  renderAction() {
    if (this.props.data.refund) {
      if (this.props.data.refund.is_refunded === 1) {
        return (
          <div className="bill-btn">
            <span className="w-100 green-text">Refund Completed</span>
          </div>
        );
      } else {
        return (
          <div className="bill-btn">
            <span className="w-100 red-text">Not Refunded</span>
          </div>
        );
      }
    } else {
      if (
        this.props.data.payment_status === "1" &&
        this.props.data.full_payment_status === "1"
      ) {
        if (this.state.downloadingInvoice) {
          return (
            <div className="bill-btn">
              <center>
                <Spinner intent={Intent.PRIMARY} size={25} />
              </center>
            </div>
          );
        } else {
          return (
            <div className="bill-btn">
              <Button
                intent={Intent.PRIMARY}
                onClick={() => this.downloadInvoice(this.props.data.order_id)}
              >
                Invoice
              </Button>
            </div>
          );
        }
      } else {
        if (this.props.data.full_payment_status === "0") {
          return (
            <div className="bold">
              <span className="w-100 red-text">Cancelled</span>
            </div>
          );
        } else if (this.props.data.full_payment_status === "1") {
          return (
            <div className="bold">
              <span className="w-100 green-text">Successful</span>
            </div>
          );
        } else if (this.props.data.full_payment_status === "3") {
          return (
            <div className="bold">
              <span className="w-100 red-text">Awaiting</span>
            </div>
          );
        } else if (this.props.data.full_payment_status === "4") {
          return (
            <div className="bold">
              <span className="w-100 red-text">Pending</span>
            </div>
          );
        } else {
          return (
            <div className="bold">
              <span className="w-100 red-text">Failed</span>
            </div>
          );
        }
      }
    }
  }
  render() {
    return (
      <tr>
        <td className="bill-row">
          <img
            src={this.props.data.service_provider.icon}
            alt={this.props.data.service_provider.service_provider_name}
          />
        </td>
        <td className="bill-row">
          <div className="operator-name">
            {this.props.data.service_provider.service_provider_name}
          </div>
        </td>
        <td className="bill-row">
          <div className="mobile-number">{this.props.data.bill_no}</div>
        </td>
        <td className="bill-row">
          <div className="bold">{this.props.data.amount}</div>
        </td>
        <td className="bill-row">{this.renderAction()}</td>
      </tr>
    );
  }
}
