import React from 'react';
import {
    RadioGroup,
    Radio,
    FormGroup,
    Intent,
    Button,
    Toaster,
    Position,
    Classes,
    Checkbox,
    Dialog
} from "@blueprintjs/core";
import Select from "react-select";
import config from "../../../core/Config";
import {ssoInstance} from "gg-react-utilities";
import {generateSessionId, getAllUrlParams, validateForm} from "../../../core/utils/Helper";
import {Auth} from "gg-react-utilities";
import * as _ from "lodash";

class Step1 extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            step: props.step,
            allowEdit: props.allowEdit ? props.allowEdit : true,
            rechargeType: '',
            redirect: null,
            operatorOptions: [],
            isOperatorOptionsLoading: true,
            operator: "",
            number: "",
            field: {},
            validation: [],
            amount: "",
            amountError: false,
            amountErrorMessage: "",
            Error: [],
            ErrorMessage: [],
            operatorError: false,
            operatorErrorMessage: "",
            services:null,
            getBillBtn:true,
            operatorSelection:{},
            payBtnLoading:false,
            billData:{},
            isOpen:false
        };
    }
    componentDidMount(){
        let urlData = getAllUrlParams();

        let mobile = urlData.bill_no ? urlData.bill_no : null;
        let type = urlData.type ? parseInt(urlData.type, 10) : 2;
        let operator = urlData.operator ? parseInt(urlData.operator, 10) : null;
        let getOperator = true;
        if(mobile && type && operator){
            getOperator = false;
            let field = this.state.field;
            field['param_1'] = mobile;
            this.setState({field, rechargeType: type, operator});
        }
        /*if(getOperator) {
            this.getOperatorOptions(this.state.rechargeType);
        }

        if(this.props.services && this.props.services.service_id){
            this.getOperatorOptions(this.props.services.service_id)
        }*/
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.services && this.state.services == null || nextProps.services.service_id != this.state.services.service_id){
            this.getOperatorOptions(nextProps.services.service_id)
            this.setState({operatorOptions:[], operatorSelection:{}})
        }

        this.setState({
            allowEdit: nextProps.allowEdit,
            services: nextProps.services,
            rechargeType: nextProps.rechargeType
        });

    }
    getBill(e){
        let canProceed = true;
        let Error = [];
        let ErrorMessage = [];

        this.state.validation.map((data)=>{
            if(data.required == true && this.state.field[data.field] != '' && this.state.field[data.field] != undefined) {
                if(data.regex != '') {
                    let re = new RegExp(data.regex, "i");
                    if (!re.test(this.state.field[data.field])) {
                        Error[data.field] = true;
                        ErrorMessage[data.field] = 'Invalid ' + data.label +' !';
                        canProceed = false;
                    } else {
                        Error[data.field] = false;
                        ErrorMessage[data.field] ='';
                    }
                } else {
                    Error[data.field] = false;
                    ErrorMessage[data.field] ='';
                }
            }else{
                Error[data.field] = true;
                ErrorMessage[data.field] = data.label + ' field is required !';
                canProceed = false;
            }
        })

        this.setState({Error,ErrorMessage})

        if(canProceed){

            let validateData = this.state.field;

            validateData.id = this.state.operator;

            this.setState({payBtnLoading: true});
            validateData = Object.assign({}, validateData)

            ssoInstance.post(config.API_URL + this.state.services.service_slug + "/get-bill", validateData)
                .then((res) => {
                    let source = res.data;
                    if (source.status) {
                        this.setState({billData: source.data, isOpen: true})
                        if (source.data.quick_payment == false && source.data.payment_exactness == 'exact') {
                            let requestData = {};
                            requestData.request_id = source.data.request_id;
                            requestData.provider = this.state.operator;
                            requestData.session_id = generateSessionId();
                            requestData.is_upi = 1;
                            if (source.data.amount) {
                                requestData.amount = source.data.amount
                            }
                            this.props.validateData(requestData);
                        } else {
                            this.setState({billData: source.data, amount: source.data.amount, getBillBtn: false})
                        }
                    }else{
                        Toaster.create({position: Position.TOP_RIGHT}).show({
                            message: source.message,
                            intent: Intent.DANGER
                        });
                    }
                    this.setState({payBtnLoading: false});
                })
                .catch((error) => {
                    Toaster.create({position: Position.TOP_RIGHT}).show({
                        message: 'something went wrong',
                        intent: Intent.DANGER
                    });
                    this.setState({payBtnLoading: false});
                });
        }


    }

    proceedPayment(e){

        let fieldsToBeChecked = [
            {state: "amount", validation: ["required", "min:"+(this.state.billData.min_amount/100), "max:"+(this.state.billData.max_amount/100), "numeric"]},

        ];

        let canProceed =  validateForm(this, fieldsToBeChecked);

        if(canProceed){

            let validateData = {};

            validateData.amount = this.state.amount;

            validateData.request_id = this.state.billData.request_id;

            this.setState({payBtnLoading:true});

            ssoInstance.post(config.API_URL + "update-cart", validateData)
                .then((res) => {
                    let source = res.data;
                    if(source.status){
                            let requestData = {};
                            requestData.request_id = source.data.request_id;
                            requestData.provider = this.state.operator;
                            requestData.session_id = generateSessionId();
                            requestData.is_upi = 1;
                            this.props.validateData(requestData);
                    }else{
                        Toaster.create({position: Position.TOP_RIGHT}).show({
                            message: source.message,
                            intent: Intent.DANGER
                        });
                    }
                    this.setState({payBtnLoading:false});
                    /*this.setState({paymentLoading: true, allowEdit: false, step: 2});
                    validateData.platform = "web";
                    ssoInstance.post(config.API_URL+"mobile/validate", validateData)
                        .then((res) => {
                            this.setState({orderTotal: res.data.data.payment_data, userGGVouchers: res.data.data.gg_vouchers_list, orderData: validateData, paymentLoading: false, allowedPayment: true});
                        })
                        .catch((error) => {
                            let message = error.response.data.message ? error.response.data.message : 'Something went wrong, try again.';
                            this.setState({allowEdit: false, allowedPayment: false, paymentLoading: false, paymentError: message});
                        });*/
                })
                .catch((error) => {
                    Toaster.create({position: Position.TOP_RIGHT}).show({
                        message: 'something went wrong',
                        intent: Intent.DANGER
                    });
                    this.setState({payBtnLoading:false});
                });

        }
        if(e !== false){
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
        //return false;
    }
    getOperatorOptions(rechargeType){
        this.setState({isOperatorOptionsLoading: true,field:[]});
        ssoInstance.get(config.GET_OPERATOR+"/"+rechargeType).then((res) => {
            let operatorOptions = [];
            let operatorSelection = {};
            let validation = [];
            res.data.data.map((single) => {
                let ErrorMessage = this.state.ErrorMessage;
                let Error = this.state.Error;


                ErrorMessage[single.value] = '';
                Error[single.value] = false;

                this.setState({ErrorMessage,Error});

                if(single.id === parseInt(this.state.operator, 10)){
                    operatorSelection = {value: single.id, label: single.service_provider_name, input_params: single.input_params};
                    single.input_params.map((data,key)=> {
                        validation[key] = {'field':data.value, 'regex': data.regex ? data.regex : '' , 'required' : !data.optional, 'label': data.label};
                    });
                }
                operatorOptions.push({value: single.id, label: single.service_provider_name, input_params: single.input_params});
                return true;
            });
            this.setState({operatorOptions, isOperatorOptionsLoading: false});
            if(!_.isEmpty(operatorSelection)) this.setState({operatorSelection,validation});
        })
        .catch((error) => {
            Toaster.create({position: Position.TOP_RIGHT}).show({
                message: 'something went wrong',
                intent: Intent.DANGER
            });
        });
    }
    onOperatorChange = (operator) => {
        let validation = [];
        if(operator.input_params == null){
            Toaster.create({position: Position.TOP_RIGHT}).show({
                message: operator.label + ' is not available! Please try again later',
                intent: Intent.DANGER
            });
            this.setState({ operatorSelection : {}});
            return false;
        }
        operator.input_params.map((data,key)=> {
            validation[key] = {'field':data.value, 'regex': data.regex ? data.regex : '' , 'required' : !data.optional, 'label': data.label};
        });

        this.setState({ operator: operator.value, operatorSelection: operator, Error:[] ,ErrorMessage: [], validation ,field:[] });
        this.props.onOperatorChange(operator.value);
    };
    onFieldChange(fieldName) {

        let field = this.state.field;

        return function (event) {
            field[fieldName] = event.target.value;
            this.setState({ field });
        }
    }
    handleClose = () => {
        this.setState({
            isOpen: false
        })
    }

    /*changeRechargeType(event){
        this.setState({
            rechargeType: parseInt(event.target.value, 10)
        },
            this.getOperatorOptions(parseInt(event.target.value, 10)),
            this.props.changeRechargeType(parseInt(event.target.value, 10))
        );
    }*/
    render(){
        return(
            <React.Fragment>
                {
                    this.state.services && this.state.services.service_type ?
                        <React.Fragment>
                            <h3 className="bp3-text-xlarge heading mb-3 mt-2">{this.state.services.service_type}
                            </h3>
                            <form className="form-data pt-0">
                                <FormGroup
                                    helperText={this.state.operatorErrorMessage}
                                    intent={this.state.operatorError ? Intent.DANGER : Intent.NONE}
                                >
                                    <Select
                                        className="react-select"
                                        placeholder="Select Operator"
                                        isDisabled={this.state.allowEdit === false}
                                        style={{marginTop: "20px"}}
                                        value={this.state.operatorSelection}
                                        onChange={this.onOperatorChange}
                                        isLoading={this.state.isOperatorOptionsLoading}
                                        options={this.state.operatorOptions}
                                    />
                                </FormGroup>
                                {
                                    this.state.operatorSelection && this.state.operatorSelection.input_params ?
                                        this.state.operatorSelection.input_params.map((data,key)=>{
                                            return(
                                                <FormGroup
                                                    helperText={this.state.ErrorMessage[data.value]}
                                                    intent={this.state.Error[data.value] ? Intent.DANGER : Intent.NONE}
                                                >
                                                    <input className="bp3-input"
                                                           disabled={this.state.allowEdit === false}
                                                           value={this.state.field[data.value]}
                                                           intent={this.state.Error[data.value] ? Intent.DANGER : Intent.NONE}
                                                           onChange={this.onFieldChange(data.value).bind(this)}
                                                           required={data.optional ? false : true}
                                                    />
                                                    <label className="form-control-placeholder">{data.label}</label>
                                                </FormGroup>
                                            )
                                        })
                                    :''
                                }
                                {
                                    this.state.getBillBtn == false ?

                                        <FormGroup
                                            helperText={this.state.amountErrorMessage}
                                            intent={this.state.amountError ? Intent.DANGER : Intent.NONE}
                                        >
                                            <input className="bp3-input"
                                                   disabled={this.state.allowEdit === false}
                                                   defaultValue={this.state.amount}
                                                   intent={this.state.amountError ? Intent.DANGER : Intent.NONE}
                                                   onChange={(e)=>{ this.setState({ amount: e.target.value})}}
                                                   required={true}
                                            />
                                            <label className="form-control-placeholder">Amount</label>
                                        </FormGroup>

                                    :''
                                }
                                {
                                    this.state.allowEdit && this.state.operatorSelection && this.state.operatorSelection.input_params ?
                                        <div className="proceed-btn">
                                            <Button
                                                loading={this.state.payBtnLoading}
                                                intent={Intent.PRIMARY}
                                                onClick={(e) =>{ this.state.getBillBtn ? this.getBill(e) : this.proceedPayment(e)}}>Proceed</Button>
                                        </div>
                                        : ""
                                }

                            </form>
                            <Dialog
                                isOpen={this.state.isOpen}
                                title={this.state.services.service_type + " Details"}
                                icon={this.props.icon ? this.props.icon : "info-sign"}
                                onClose={this.handleClose}
                                {...this.props}>

                                {/*<h3 className="bp3-text-xlarge heading ml-5 mt-4">Apply GG Voucher</h3>*/}

                                <div className={Classes.DIALOG_BODY + ' voucher-list-model'}>
                                    {
                                        this.state.billData.biller_details_info && this.state.billData.biller_details_info.length > 0 ?
                                            <React.Fragment>
                                                {
                                                    this.state.billData.biller_details_info.map((data, key) => {
                                                        return (
                                                            <div className="pay-btn row" key={key}>
                                                                <div className="col-md-6 col-sm-6 col-6 text-left bp3-text-xlarge heading mb-3 mt-2">
                                                                    <p>{data.key}</p>
                                                                </div>
                                                                <div className="col-md-6 col-sm-6 col-6 text-right">
                                                                    <p>{data.value}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </React.Fragment>
                                            :
                                            ''
                                    }
                                </div>

                            </Dialog>
                        </React.Fragment>
                    :''
                }
            </React.Fragment>
        );
    }
}

export default Step1