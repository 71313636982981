import React from 'react';
import {InputGroup, FormGroup, Intent, Button} from "@blueprintjs/core";
import CheckRounded from '@material-ui/icons/CheckRounded';
import Select from "react-select";
import config from "../../../core/Config";
import {ssoInstance} from 'gg-react-utilities';
import {generateSessionId, validateForm} from "../../../core/utils/Helper";
import {Auth} from "gg-react-utilities";

export default class Step1 extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            step: props.step,
            allowEdit: props.allowEdit ? props.allowEdit : true,
            redirect: null,
            operatorOptions: [],
            isOperatorOptionsLoading: true,
            operator: "",
            number: "",
            amount: "",
            account: "",
            amountError: false,
            amountErrorMessage: "",
            numberError: false,
            numberErrorMessage: "",
            operatorError: false,
            operatorErrorMessage: ""
        };
    }
    componentDidMount(){
        this.getOperatorOptions(config.TYPE_ELECTRICITY);
        this.getCityOptions();
    }
    componentWillReceiveProps(nextProps){
        this.setState({allowEdit: nextProps.allowEdit, amount: nextProps.amount});
    }
    getCityOptions(){
        ssoInstance.get(config.GET_CITYLIST).then((res) => {
            let cityOptions = [];
            res.data.data.map((single) => {
                cityOptions.push({value: single, label: single});
                return true;
            });
            this.setState({cityOptions, isCityOptionsLoading: false});
        });
    }
    onCityChange = (city) => {
        this.setState({ city: city.value, citySelection: city });
    };
    onOperatorChange = (operator) => {
        this.setState({ operator: operator.value, operatorSelection: operator });
    };
    getOperatorOptions(typeId){
        this.setState({isOperatorOptionsLoading: true});
        ssoInstance.get(config.GET_OPERATOR+"/"+typeId).then((res) => {
            let operatorOptions = [];
            res.data.data.map((single) => {
                operatorOptions.push({value: single.id, label: single.service_provider_name});
                return true;
            });
            this.setState({operatorOptions, isOperatorOptionsLoading: false});
        });
    }
    proceedPayment(e){
        
        let fieldsToBeChecked = [
            {state: "operator", validation: ["required"]},
            {state: "amount", validation: ["required", "numeric"]},
            {state: "number", validation: ["required"], message: ["Bill No. is required."]},
        ];

        if(this.state.operator === 51){
            fieldsToBeChecked.push({state: "cycle", validation: ["required"]});
        }
        if(this.state.operator === 56){
            fieldsToBeChecked.push({state: "account", validation: ["required"]});
            fieldsToBeChecked.push({state: "pc", validation: ["required"]});
        }
        if(this.state.operator === 58){
            fieldsToBeChecked.push({state: "city", validation: ["required"]});
        }
        if(this.state.operator === 114){
            fieldsToBeChecked.push({state: "subdivision", validation: ["required"]});
        }
        if(this.state.operator === 122){
            fieldsToBeChecked.push({state: "mobile", validation: ["required", "length:10"]});
        }

        let canProceed =  validateForm(this, fieldsToBeChecked);
        
        if(canProceed){
            if(!Auth.isLoggedIn()){
                window.history.pushState({},"", "?number="+this.state.number);
            }
            let validateData = {};
            let operator = this.state.operator;
            validateData.number = this.state.number;
            validateData.type = config.TYPE_ELECTRICITY;
            validateData.session_id = generateSessionId();
            validateData.amount = this.state.amount;
            validateData.provider = operator;
            validateData.account = this.state.city;
            if(operator === 51){
                validateData.account = this.state.cycle;
            }
            if(operator === 56){
                validateData.account = this.state.account;
                validateData.authenticator = this.state.pc;
            }
            if(operator === 58){
                validateData.city = this.state.city;
            }
            if(operator === 114){
                validateData.account = this.state.subdivision;
            }
            if(operator === 122){
                validateData.account = this.state.mobile;
            }
            this.props.validateData(validateData);
        }
        e.preventDefault();
        e.stopPropagation();
        return false;
    }
    renderHeader(){
        if(this.state.step === 1){
            return(
                <React.Fragment>
                    <h3 className="bp3-text-xlarge heading mt-2 mb-2">Pay your Electricity Bill</h3>
                    <h6 className="bp3-text-small heading mt-1">Just give us a few details</h6>
                </React.Fragment>
            );
        }
        if(this.state.step === 2){
            return (
                <React.Fragment>
                    <h1 className="bp3-text-xlarge heading mb-3">Pay your Electricity Bill
                        <div className="data-valid">
                            <span className="correct"><CheckRounded style={{fontSize: '14'}}/></span>
                        </div>
                    </h1>
                </React.Fragment>
            );
        }
    }
    getExtraFields(operator){
        switch (operator){
            case 51:
                return <FormGroup
                    helperText={this.state.cycleErrorMessage}
                    intent={this.state.cycleError ? Intent.DANGER : Intent.NONE}
                >
                    <InputGroup placeholder="Enter Cycle No."
                                disabled={this.state.allowEdit === false}
                                defaultValue={this.state.cycle}
                                intent={this.state.cycleError ? Intent.DANGER : Intent.NONE}
                                onChange={this.onFieldChange('cycle').bind(this)}
                    />
                </FormGroup>;
            case 56:
                return (<React.Fragment>
                    <FormGroup
                        helperText={this.state.accountErrorMessage}
                        intent={this.state.accountError ? Intent.DANGER : Intent.NONE}
                    >
                        <input  className="bp3-input"
                                required
                                disabled={this.state.allowEdit === false}
                                defaultValue={this.state.account}
                                intent={this.state.accountError ? Intent.DANGER : Intent.NONE}
                                onChange={this.onFieldChange('account').bind(this)}
                        />
                        <label className="form-control-placeholder">Account Billing Unit</label>
                    </FormGroup>
                    <FormGroup
                        helperText={this.state.pcErrorMessage}
                        intent={this.state.pcError ? Intent.DANGER : Intent.NONE}
                    >
                        <input  className="bp3-input"
                                required
                                disabled={this.state.allowEdit === false}
                                defaultValue={this.state.pc}
                                intent={this.state.pcError ? Intent.DANGER : Intent.NONE}
                                onChange={this.onFieldChange('pc').bind(this)}
                        />
                        <label className="form-control-placeholder">Processing Cycle(PC)</label>
                    </FormGroup>
                </React.Fragment>);
            case 58:
                return (
                    <FormGroup
                        helperText={this.state.cityErrorMessage}
                        intent={this.state.cityError ? Intent.DANGER : Intent.NONE}
                    >
                        <Select
                            className="react-select"
                            placeholder="Select City"
                            style={{marginTop: "20px"}}
                            value={this.state.citySelection}
                            onChange={this.onCityChange}
                            isDisabled={this.state.allowEdit === false}
                            isLoading={this.state.isCityOptionsLoading}
                            options={this.state.cityOptions}
                        />
                    </FormGroup>
                );
            case 114:
                return(
                    <FormGroup
                        helperText={this.state.subdivisionErrorMessage}
                        intent={this.state.subdivisionError ? Intent.DANGER : Intent.NONE}
                    >
                        <input  className="bp3-input"
                                required
                                disabled={this.state.allowEdit === false}
                                defaultValue={this.state.subdivision}
                                intent={this.state.subdivisionError ? Intent.DANGER : Intent.NONE}
                                onChange={this.onFieldChange('subdivision').bind(this)}
                        />
                        <label className="form-control-placeholder">Subdivision Code</label>
                    </FormGroup>
                );
            case 122:
                return(
                    <FormGroup
                        helperText={this.state.mobileErrorMessage}
                        intent={this.state.mobileError ? Intent.DANGER : Intent.NONE}
                    >
                        <input  className="bp3-input"
                                required
                                disabled={this.state.allowEdit === false}
                                defaultValue={this.state.mobile}
                                intent={this.state.mobileError ? Intent.DANGER : Intent.NONE}
                                onChange={this.onFieldChange('mobile').bind(this)}
                        />
                        <label className="form-control-placeholder">Bill Registered Mobile Number</label>
                    </FormGroup>
                );
            default:
                return "";
        }
    }
    onFieldChange(fieldName) {
        return function (event) {
            this.setState({ [fieldName]: event.target.value });
        }
    }
    render(){
        return(
            <React.Fragment>
                {
                    this.renderHeader()
                }
                <form className="form-data">
                    <FormGroup
                        helperText={this.state.operatorErrorMessage}
                        intent={this.state.operatorError ? Intent.DANGER : Intent.NONE}
                    >
                        <Select
                            className="react-select"
                            placeholder="Select Operator"
                            style={{marginTop: "20px"}}
                            value={this.state.operatorSelection}
                            onChange={this.onOperatorChange}
                            isDisabled={this.state.allowEdit === false}
                            isLoading={this.state.isOperatorOptionsLoading}
                            options={this.state.operatorOptions}
                        />
                    </FormGroup>
                    <FormGroup
                        helperText={this.state.numberErrorMessage}
                        intent={this.state.numberError ? Intent.DANGER : Intent.NONE}
                    >
                        <input  className="bp3-input"
                                required
                                disabled={this.state.allowEdit === false}
                                defaultValue={this.state.number}
                                intent={this.state.numberError ? Intent.DANGER : Intent.NONE}
                                onChange={this.onFieldChange('number').bind(this)}
                        />
                        <label className="form-control-placeholder">Enter Electricity Bill/Account No</label>
                    </FormGroup>
                    <FormGroup
                        helperText={this.state.amountErrorMessage}
                        intent={this.state.amountError ? Intent.DANGER : Intent.NONE}
                    >
                        <input  className="bp3-input"
                                required
                                disabled={this.state.allowEdit === false}
                                defaultValue={this.state.amount}
                                type="number"
                                intent={this.state.amountError ? Intent.DANGER : Intent.NONE}
                                onChange={this.onFieldChange('amount').bind(this)}
                        />
                        <label className="form-control-placeholder">Enter Amount</label>
                    </FormGroup>
                    {
                        this.getExtraFields(this.state.operator)
                    }
                    {
                        this.state.allowEdit ?
                            <div className="proceed-btn">
                                <Button type="submit" intent={Intent.PRIMARY} onClick={(e) => this.proceedPayment(e)}>Proceed</Button>
                            </div>
                            : ""
                    }
                </form>
            </React.Fragment>
        );
    }
}
