import React from 'react';
import {FormGroup, Intent, Button} from "@blueprintjs/core";
import Select from "react-select";
import config from "../../../core/Config";
import {ssoInstance} from 'gg-react-utilities';
import {generateSessionId, getAllUrlParams, validateForm} from "../../../core/utils/Helper";
import {Auth} from "gg-react-utilities";

export default class Step1 extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            step: props.step,
            allowEdit: props.allowEdit ? props.allowEdit : true,
            redirect: null,
            operatorOptions: [],
            isOperatorOptionsLoading: true,
            operator: "",
            number: "",
            amount: null,
            account: "",
            amountError: false,
            amountErrorMessage: "",
            numberError: false,
            numberErrorMessage: "",
            operatorError: false,
            operatorErrorMessage: ""
        };
    }
    componentDidMount(){
        this.getOperatorOptions();

        let urlData = getAllUrlParams();

        let number = urlData.number ? urlData.number : null;
        let amount = urlData.amount ? urlData.amount : null;
        let type = urlData.type ? parseInt(urlData.type, 10) : 2;
        let operator = urlData.operator ? parseInt(urlData.operator, 10) : null;
        let authenticator = urlData.authenticator ? parseInt(urlData.authenticator, 10) : null;
        let account = urlData.account ? urlData.account : null;
        if(number && amount && type && operator && (authenticator || account)){
            this.setState({number:number, amount, operator, authenticator, account},
                () => {this.proceedPayment(false)}
            );
        }
    }
    componentWillReceiveProps(nextProps){
        this.setState({allowEdit: nextProps.allowEdit, amount: nextProps.amount});
    }
    getOperatorOptions(){
        this.setState({isOperatorOptionsLoading: true});
        ssoInstance.get(config.GET_OPERATOR+"/"+config.TYPE_LANDLINE).then((res) => {
            let operatorOptions = [];
            res.data.data.map((single) => {
                operatorOptions.push({value: single.id, label: single.service_provider_name});
                return true;
            });
            this.setState({operatorOptions, isOperatorOptionsLoading: false});
        });
    }
    onOperatorChange = (operator) => {
        this.setState({ operator: operator.value, operatorSelection: operator });
        this.props.onOperatorChange(operator.value);
    };
    onAuthenticatorChange = (authenticator) => {
        this.setState({ authenticator: authenticator.value, authenticatorSelection: authenticator });
    };
    onFieldChange(fieldName) {
        return function (event) {
            this.setState({ [fieldName]: event.target.value });
        }
    }
    getExtraFields(operator){
        switch (operator){
            case 50:
                return <FormGroup
                    helperText={this.state.authenticatorErrorMessage}
                    intent={this.state.authenticatorError ? Intent.DANGER : Intent.NONE}
                >
                    <Select
                        className="react-select"
                        placeholder="Customer Authenticator"
                        isDisabled={this.state.allowEdit === false}
                        style={{marginTop: "20px"}}
                        value={this.state.authenticatorSelection}
                        onChange={this.onAuthenticatorChange}
                        options={[{value: "LLI", label: "LLI (Landline Individual)"}, {value: "LLC", label: "LLC (Landline Corporate)"}]}
                    />
                </FormGroup>;
            default:
                return "";
        }
    }
    proceedPayment(e){
        let fieldsToBeChecked = [
            {state: "operator", validation: ["required"]},
            {state: "amount", validation: ["required", "numeric"]},
            {state: "account", validation: ["required"]}
        ];
        if(this.state.operator === 48){
            fieldsToBeChecked.push({state: "number", validation: ["required", "length:8", "numeric"], message: ["Telephone No. is required.", "Telephone number must be 8 character long."]});
        }
        if(this.state.operator === 50){
            fieldsToBeChecked.push({state: "number", validation: ["required", "length:10", "numeric"],
                message: ["Bill No. is required.", "Telephone number must be 10 character long."]});
            fieldsToBeChecked.push({state: "authenticator", validation: ["required"]});
        }
        let canProceed =  validateForm(this, fieldsToBeChecked);

        if(canProceed){

            if(!Auth.isLoggedIn()){
                let urlQuery = "?number="+this.state.number+
                    "&authenticator="+this.state.authenticator+
                    "&account="+this.state.account+
                    "&amount="+this.state.amount+
                    "&operator="+this.state.operator;
                window.history.pushState({},"", urlQuery);
            }
            let validateData = {};
            validateData.number = this.state.number;
            validateData.type = config.TYPE_LANDLINE;
            validateData.session_id = generateSessionId();
            validateData.amount = this.state.amount;
            validateData.provider = this.state.operator;
            validateData.authenticator = this.state.authenticator;
            validateData.account = this.state.account;
            this.props.validateData(validateData);
        }
        if(e !== false){
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    }
    render(){
        return(
            <React.Fragment>
                <h1 className="bp3-text-xlarge heading mb-3">Pay your Landline Bill
                    <div className="data-valid">
                        {
                            /*this.state.allowedPayment ? <span className="correct"><CheckRounded style={{fontSize: '14'}}/></span>
                                : <span className="in-correct"><ClearRounded style={{fontSize: '15'}}/></span>*/
                        }
                    </div>
                </h1>
                <form className="form-data">
                    <FormGroup
                        helperText={this.state.operatorErrorMessage}
                        intent={this.state.operatorError ? Intent.DANGER : Intent.NONE}
                    >
                        <Select
                            className="react-select"
                            placeholder="Select Operator"
                            isDisabled={this.state.allowEdit === false}
                            style={{marginTop: "20px"}}
                            value={this.state.operatorSelection}
                            onChange={this.onOperatorChange}
                            isLoading={this.state.isOperatorOptionsLoading}
                            options={this.state.operatorOptions}
                        />
                    </FormGroup>
                    <FormGroup helperText={this.state.numberErrorMessage}
                               intent={this.state.numberError ? Intent.DANGER : Intent.NONE}>
                        <input  className="bp3-input"
                                required
                                disabled={this.state.allowEdit === false}
                                defaultValue={this.state.number}
                                onChange={this.onFieldChange('number').bind(this)}
                        />
                        <label className="form-control-placeholder">Enter Telephone Number</label>
                    </FormGroup>
                    <FormGroup
                        helperText={this.state.amountErrorMessage}
                        intent={this.state.amountError ? Intent.DANGER : Intent.NONE}
                    >
                        <input  className="bp3-input"
                                required
                                disabled={this.state.allowEdit === false}
                                defaultValue={this.state.amount}
                                intent={this.state.amountError ? Intent.DANGER : Intent.NONE}
                                onChange={this.onFieldChange('amount').bind(this)}
                        />
                        <label className="form-control-placeholder">Enter Amount</label>
                    </FormGroup>
                    <FormGroup
                        helperText={this.state.accountErrorMessage}
                        intent={this.state.accountError ? Intent.DANGER : Intent.NONE}
                    >
                        <input  className="bp3-input"
                                required
                                disabled={this.state.allowEdit === false}
                                defaultValue={this.state.account}
                                intent={this.state.accountError ? Intent.DANGER : Intent.NONE}
                                onChange={this.onFieldChange('account').bind(this)}
                        />
                        <label className="form-control-placeholder">Customer Account Number</label>
                    </FormGroup>
                    {
                        this.getExtraFields(this.state.operator)
                    }
                    {
                        this.state.allowEdit ?
                            <div className="proceed-btn">
                                <Button type="submit" intent={Intent.PRIMARY} onClick={(e) => this.proceedPayment(e)}>Proceed</Button>
                            </div>
                            : ""
                    }
                </form>
            </React.Fragment>
        );
    }
}